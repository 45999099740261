
              @import "@/assets/css/variables.scss";
            



















































































































// @import '@/assets/css/pages/register/register.scss';
.register_panel {
  margin-bottom: 72px;

  .progress_title {

    p {
      font-size: 16px;
      color: #09090D;
      font-weight: 700;
      line-height: 24px;

      .svg-icon {
        color: $primary;
        @include rtl-sass-prop(margin-left, margin-right, 4px);
        cursor: pointer;
      }
    }
  }

  .register_form {

    .title {
      font-size: 14px;
      color: $text-primary;
      line-height: 22px;
      font-weight: 500;
      margin-bottom: 24px;
    }

    .el-select {
      width: 100%;
    }
  }
}

.register_footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  background: $white;
  box-shadow: 0px -4px 10px rgba(14, 18, 54, 0.1);
  padding: 16px 48px;

  @include screen-mobile {
    padding: 16px 24px;
  }
}
