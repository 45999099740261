
              @import "@/assets/css/variables.scss";
            





















































.title {
  margin: 24px 0 8px;
  font-size: 14px;
  color: $text-secondary;
  line-height: 22px;
}

.tip {
  margin-bottom: 16px;
  font-size: 12px;
  color: $text-secondary;
  line-height: 18px;
}

img {
  margin-bottom: 8px;
}

.info_content {

  /deep/ ul {
    li {
      font-size: 14px;
      color: $text-primary;
      line-height: 22px;
    }
  }
}
